import React from 'react';
import { graphql } from 'gatsby';
import chunk from 'lodash/chunk';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faCity, faEnvelope } from '@fortawesome/free-solid-svg-icons';

import Layout from '../components/layout/layout';
import HeaderExpansion from '../components/layout/header-expansion';
import ContactHeaderExpansion from '../components/contactHeaderExpansion';
import ContactCard from '../components/contactCard';
import Section from '../components/layout/section';
import FlexboxItem from '../components/layout/flexbox/flexbox--item';
import FlexboxThreePart from '../components/layout/flexbox/flexbox--three-part';

import { FixString } from '../functions/FixString';
import { GenerateSEO } from '../functions/GenerateSEO';

const Contact = ({ data: { allContentfulGrampusGContactUs: { nodes: contactUs } } }) => {
    const section1SubSections = chunk(contactUs[0].section1.sections, 3);

    const phoneIcon = <FontAwesomeIcon className="page__icon page__icon--small page__icon--large-bottom-margin" icon={ faPhone } />;
    const cityIcon = <FontAwesomeIcon className="page__icon page__icon--small page__icon--large-bottom-margin" icon={ faCity } />;
    const mailIcon = <FontAwesomeIcon className="page__icon page__icon--small page__icon--large-bottom-margin" icon={ faEnvelope } />;

    return (
      <Layout 
        contactBanner={ false }
        location="contact"
        metadata={ GenerateSEO({
          is404: false,
          lang: 'en',
          title: contactUs[0].seo.seoTitle,
          description: contactUs[0].seo.seoDescription,
          image: contactUs[0].Images[0].localFile.childImageSharp.fixed.src,
          imageAlt: contactUs[0].Images[0].description,
          url: 'https://www.grampusg.com/contact/' 
        })}>
        <HeaderExpansion tagline={ contactUs[0].tagline } />
        { /* <ContactHeaderExpansion /> */ }
        <Section className="blue-background center">
            {
                section1SubSections.map((itemContainer, outerIndex) => (
                    <FlexboxThreePart className="add-margin" key={ outerIndex }>
                        { 
                            itemContainer.map((item, innerIndex) => (
                                <FlexboxItem key={ innerIndex }>
                                    { innerIndex === 0 && outerIndex === 0 ? phoneIcon : 
                                    innerIndex === 1 && outerIndex === 0 ? cityIcon : 
                                    innerIndex === 2 && outerIndex === 0 ? mailIcon : '' }
                                    { item.header ? <h3 className="section__subheader">{ item.header }</h3> : '' }
                                    { item.content.content ? <p className="section__paragraph">{ FixString(item.content.content) }</p> : '' }
                                </FlexboxItem>
                            ))
                        }
                    </FlexboxThreePart>
                ))
            }
        </Section>
        <ContactCard contactData={ 
            { 
                image: contactUs[0].Images[0].localFile.childImageSharp.fluid, 
                imageAlt: contactUs[0].Images[0].description,
                header: contactUs[0].contactFormHeader, 
                submitButtonText: contactUs[0].submitButtonText 
            } } />
      </Layout>
  )
}

export const query = graphql`
query ContactQuery {
  allContentfulGrampusGContactUs {
    nodes {
      seo {
        seoTitle
        seoDescription
      }
      Images {
        fixed {
          src
        }
        localFile {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp
                }
                fixed {
                    src
                }
            }
        }
      }
      tagline
      contactFormHeader
      submitButtonText
      section1 {
        sections {
          header
          content {
            content
          }
        }
      }
    }
  }
}
`

export default Contact;
