import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import Image from 'gatsby-image';

import { Encode } from '../functions/Encode';

import ContactSuccess from './contactSuccess';



const ContactCard = ({ contactData }) => {
    const { register, handleSubmit, errors, formState: { isSubmitting, touched } } = useForm({
        defaultValues: {
            name: '',
            email: '',
            subject: '',
            message: ''
        }
    });

    const [_showError, _setShowError] = useState('');
    const [_showSuccess, _setSuccess] = useState('');

    const onSubmit = async (data) => {
        fetch('/',
            {
                method: 'POST',
                headers: { 'Content-type': 'application/x-www-form-urlencoded' },
                body: Encode({ 'form-name': 'grampusg', ...data })
            })
            .then(_setSuccess(<ContactSuccess success="true">Message sent</ContactSuccess>))
            .catch(error => _setShowError(<ContactSuccess success="false">Message not sent</ContactSuccess>))
    }

    return (
        <div className="section">
            { _showSuccess }
            { _showError }
            <div className="section__parent-container">
                <div className="section__flexbox section__flexbox--two-part">
                    <div className="section__flexbox-item section__image-box">
                        <Image className="section__image" fluid={ contactData.image } alt={ contactData.imageAlt }/>
                    </div>
                    <div className="section__flexbox-item">
                        <form 
                            className="contact-form" 
                            name="grampusg" 
                            method="post" 
                            data-netlify="true"
                            data-netlify-honeypot="bot-field"
                            onSubmit={ handleSubmit(onSubmit) }>
                            <input type="hidden" name="form-name" value="grampusg" />
                            <h2 className="contact-form__header section__header">{ contactData.header }</h2>
                            <div className="contact-form__form-group">
                                <input
                                    className={ errors.name && touched.name ? 'contact-form__form-input error' : 'contact-form__form-input' }
                                    type="text"
                                    placeholder="Name"
                                    name="name"
                                    ref={ register({ required: true }) }
                                    required />
                                <label className="contact-form__form-label" htmlFor="name">
                                    Name 
                                </label>
                            </div>
                            <div className="contact-form__form-group">
                                <input 
                                    className={ errors.email && touched.email ? 'contact-form__form-input error' : 'contact-form__form-input' }
                                    type="email"
                                    placeholder="Email"
                                    name="email"
                                    ref={ register({ required: true }) }
                                    required />
                                <label className="contact-form__form-label" htmlFor="email">
                                    Email
                                </label>
                            </div>
                            <div className="contact-form__form-group">
                                <input 
                                    className={ errors.subject && touched.subject ? 'contact-form__form-input error' : 'contact-form__form-input' }
                                    type="text"
                                    placeholder="Subject"
                                    name="subject"
                                    ref={ register() }
                                    required />
                                <label className="contact-form__form-label" htmlFor="subject">
                                    Subject
                                </label>
                            </div>
                            <div className="contact-form__form-group">
                                <textarea 
                                    className="contact-form__form-input contact-form__textarea"
                                    name="message"
                                    ref={ register() }></textarea>
                                <label className="contact-form__form-label" htmlFor="message">Message</label>
                            </div>
                            <div className="contact-form__form-group">
                                <input className="contact-form__form-button button secondary" type="submit" id="submit" disabled={ isSubmitting } value={ contactData.submitButtonText } />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactCard;
