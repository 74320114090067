import React from 'react';

const ContactHeaderExpansion = () => (
    <div style={ { width: '100%' } }>
        <iframe 
            title="Grampus G Map"
            style={ { display: 'block' } }
            width="100%" 
            height="400" 
            frameBorder="0" 
            scrolling="no" 
            marginHeight="0" 
            marginWidth="0" 
            src="https://maps.google.com/maps?width=100%25&amp;height=400&amp;hl=en&amp;q=3148%20PCH%20TORRANCE%20CA%2090505+(Grampus%20G%20Insurance%20Agency)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed">
        </iframe>
    </div>
);

export default ContactHeaderExpansion;
