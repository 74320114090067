import React, { useState, useEffect, useRef } from 'react';
import { navigate } from 'gatsby';

const ContactSuccess = ({ success, children }) => {
  const [_counter, _setCounter] = useState(5);
  const [_outputIcon, _setOutputIcon] = useState("");

  const _isMounted = useRef(true)

  useEffect(() => {
    if (success === 'true') {
        _setOutputIcon((<i className="material-icons material-icons--success">done_outline</i>));
    }
    else if (success === 'false') {
        _setOutputIcon((<i className="material-icons material-icons--failure">error_outline</i>));
    }
  }, [success])
    
  useEffect(() => {
    if (_isMounted.current)
        _counter > 0 && setTimeout(() => _setCounter(_counter - 1), 1000);
    if (_counter === 0 || _counter < 0) {   
        _isMounted.current = false;
        navigate('/');
    }
  }, [_counter]);

  return (
      <div className="component--contact-success">
        <div className="component--contact-success__container">
            { _outputIcon }
            <h2 className="component--contact-success__header">{ children }</h2>
            <p className="component--contact-success__content">Going back to home... { _counter }</p>
            <div className="u-margin-top-small"></div>
            <div className="u-margin-bottom-medium"></div>
        </div>
      </div>
  )
}

export default ContactSuccess;